<template>
  <div
    class="qty-wrap rounded-sm"
    :class="{ 'not-empty': quantity > 0, 'no-border': quantity < 1 }"
    @mousedown.stop
  >
    <v-btn
      v-if="enableAcqBox && product.logisticPackagingItems && quantity <= 0"
      :aria-label="
        `Aggiungi un cartone da ${product.logisticPackagingItems} al carrello`
      "
      color="primary"
      outlined
      height="45"
      class="btn-add-box mr-1"
      small
      @click.stop.prevent="
        addToCart(
          product.logisticPackagingItems,
          undefined,
          {
            searchUid: product.searchUid,
            position: position
          },
          level
        )
      "
    >
      <div>
        {{ product.logisticPackagingItems }}
        <v-icon color="primary" small>$pack</v-icon>
      </div>
      <div class="text-uppercase small">
        pezzi
      </div>
    </v-btn>

    <v-btn
      tile
      aria-label="Diminuisci quantità"
      depressed
      color="grey lighten-2"
      small
      class="minus"
      @click.stop.prevent="
        minus(
          true,
          { infos: { ...selectedOptions } },
          {
            searchUid: product.searchUid,
            position: position
          },
          level
        )
      "
      @mousedown.stop
    >
      <v-icon>$minus</v-icon>
    </v-btn>
    <div class="val-cnt">
      <span class="val">{{ quantity }}{{ unit }}</span>
      <span class="small">{{ quantityPerUnit }}</span>
    </div>
    <v-btn
      tile
      aria-label="Aumenta quantità"
      class="plus"
      small
      :class="quantity > 0 ? '' : 'rounded-sm'"
      depressed
      :color="quantity > 0 ? 'grey lighten-2' : 'primary'"
      @click.stop.prevent="
        handleAdd(
          true,
          {
            infos: {
              ...selectedOptions
            }
          },
          {
            searchUid: product.searchUid,
            position: position
          },
          level
        )
      "
    >
      <!-- v-ripple -->
      <v-icon v-if="quantity > 0">$plus</v-icon>
      <template v-else>
        <v-icon>$cart</v-icon>
        <span v-if="label" class="font-weight-bold white--text text-uppercase">
          {{ label }}
        </span>
      </template>
    </v-btn>
  </div>
</template>
<script>
import productMixin from "~/mixins/product";
import CrossSellingDialog from "./CrossSellingDialog.vue";

export default {
  name: "ProductQty",
  data() {
    return {
      products: [],
      productsList: [],
      dialogInstance: {}
    };
  },
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined },
    level: { type: String, default: undefined }
  },
  // eslint-disable-next-line vue/no-unused-components
  mixins: [productMixin],
  computed: {
    enableAcqBox() {
      return (
        this.product.productInfos?.ENABLE_ACQ_BOX &&
        this.product.productInfos?.ENABLE_ACQ_BOX !== "no"
      );
    },
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          this.product.logisticPackagingItems > 0 &&
          this.item.quantity % this.product.logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / this.product.logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    }
  },
  methods: {
    async handleAdd(updateCartParam, params, searchParams) {
      await this.plus(updateCartParam, params, searchParams);
      //open cross-selling popup
      if (
        this.quantity == 0 &&
        this.product &&
        this.product.entityRelationship
      ) {
        let _this = this;
        _this.dialogInstance = await this.$dialog.show(CrossSellingDialog, {
          waitForResult: true,
          product: this.product
        });

        if (_this.dialogInstance) {
          _this.$emit("submit", true);
          _this.productsList = [];
        } else {
          _this.$emit("submit", false);
          _this.productsList = [];
        }
        // for (let index in this.product.entityRelationship) {
        //   if (Array.isArray(this.product.entityRelationship[index])) {
        //     this.products = this.product.entityRelationship[index];
        //     break;
        //   }
        // }
        // if (this.products.length > 0) {
        //   //open cross-selling popup
        //   for (var i = 0; i < this.products.length; i++) {
        //     if (this.products[i].slug) {
        //       let product = await ProductService.getProductBySlug(
        //         this.products[i].slug
        //       );
        //       if (product) this.productsList.push(product);
        //     }
        //   }
        //   let _this = this;
        //   _this.dialogInstance = await this.$dialog.show(CrossSellingDialog, {
        //     waitForResult: true,
        //     fullscreen: this.$vuetify.breakpoint.smAndDown,
        //     products: this.productsList
        //   });

        //   if (_this.dialogInstance) {
        //     _this.$emit("submit", true);
        //     _this.productsList = [];
        //   } else {
        //     _this.$emit("submit", false);
        //     _this.productsList = [];
        //   }
        // }
      }
    }
  }
};
</script>
